/* SwipeableStatement.css */

.swipeable-statement {
  position: relative;
  user-select: none;
  display: flex;
  align-items: center; /* This will vertically align the content */
  justify-content: center; /* This will horizontally align the content */
  text-align: center;
  font-size: 1.5rem; /* Adjust the size as needed */
  /* Have tick font */
  background-image: linear-gradient(
    to right,
    #dbf227 0%,
    #9fc131 100%
  ); /* Example gradient */
  width: 100%;
  height: 600px; /* Fixed height */
  border-radius: 30px; /* Rounded corners */
  border: #042940;
  box-shadow: 0 4px 6px rgba(0.5, 0, 0, 1); /* Soft shadow for depth */
  margin: 100 auto; /* For horizontal centering if the card is inside a flex or grid container */
}

.statement-content {
  padding: 20px;
  text-align: center;
}

.statement-text {
  font-size: 24px;
  color: #042940;
  -webkit-text-stroke: 0.01px white;
  font-weight: 1000;
  font: "Pacifico", sans-serif;
}
