/* App.css */
body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrolling on the entire page */
  height: 100%; /* Ensure the page takes up full viewport height */
  width: 100%; /* Ensure the page takes up full viewport width */
}

#root,
.App {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* make pattern design as background https://color.adobe.com/Green-Globe-On-Moss---Environmental-Concept--color-theme-12819809/*/

  background: repeating-linear-gradient(
    45deg,
    #d6d58e,
    #d6d58e 5px,
    #005c53 5px,
    #042940 6px
  );
}
