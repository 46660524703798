.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  padding: 20px;
}

.card {
  background-image: linear-gradient(#597e1e, #042940);
  background-image: linear-gradient(to right, #dbf227 0%, #9fc131 100%);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-size: 1.5rem;
}

.card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.card img {
  width: 100%;
  height: auto;
}

.card-content {
  padding: 15px;
}

.link {
  text-decoration: none;
  color: #042940;
}
